<template>
    <div>
       <svg class="bnb-icon" aria-hidden="true">
          <use xlink:href="#icon-a-zu206"></use>
        </svg>
    </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style scoped lang="scss">

</style>
